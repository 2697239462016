@import url('https://fonts.googleapis.com/css2?family=DM+Mono:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&display=swap');

:root {
    --font-color: #495057;
    --heading-color: #343a40;
    --background: white;
    --dark-font-color: #1b1d25;
    --medium-font-color: #60656c;
    --light-font-color: #858b93;
    --light-background: #f1f4f8;
    --dark-background-intro: #2d2d31;
    --light-background-hover: #e1e6ed;
    --border: #d6d9de;
    --link-color: #5183f5;
    --link-color-darker: #364fc7;
    --navbar-color: #1d1d1d;
    --blockquote: #757b88;
    --transparent-text: rgba(0, 0, 0, 0.7);
    --transparent-bg: rgba(0, 0, 0, 0.05);
    --light-transparent-bg: rgba(255, 255, 255, 0.1);
    --font-family: 'DM Sans', -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Roboto', Roboto, Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    --heading-font-family: 'DM Mono', Menlo, monospace;
    --code-font-family: Menlo, 'Roboto Mono', Courier New, monospace;
    --border-color: var(--dark-font-color);
    --yellow: rgba(254, 220, 71, 1);
    --yellow-orange: rgba(253, 186, 58, 1);
    --orange: rgba(253, 152, 48, 1);
    --red-orange: rgba(253, 119, 39, 1);
    --subtle-border: rgba(0, 0, 0, 0.1);
    --green: #35a353;
    --light-green: #92d192;
    --marker: #5a43f1;
}

body {
    font-family: var(--font-family);
    color: var(--font-color);
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.75;
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

.container {
    max-width: 950px;
}

.header_container {
    display: block;
    box-sizing: border-box;
    z-index: 2;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 5rem;
    border: none;
    background-color: var(--navbar-color);
    letter-spacing: -.05rem;
}

.icon_img_text_container {
    color: #fff;
    background: transparent !important;
    font-size: 1.5rem;
    white-space: nowrap;
    font-weight: 700;
    line-height: 1;
    padding: .5rem 0;
    font-family: var(--heading-font-family);
    box-sizing: border-box;
    border: none;
}

.menu-item {
    padding: .8rem 0;
    font-size: 1.5rem;
    grid-gap: .75rem;
    grid-column: auto;
    display: flex;
    justify-self: flex-end;
    grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
    text-align: center;
    box-sizing: border-box;
}

a.menu-item {
    align-items: center;
}

a.head_img_txt_link {
    color: #fff;
    display: flex;
    align-items: center;
    padding: 1rem 1.25rem;
}

a.head_img_txt_link img {
    margin-right: .25rem;
}

span.head_img {
    margin: 0 .5rem 0 .1rem;
}

a.head_menu_link {
    color: hsla(0, 0%, 100%, .8);
    align-items: center;
    background-color: #2d2d31;
    padding: 1rem 1.25rem;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1;
    font-family: var(--heading-font-family);
    border: 1px solid transparent;
}

a.head_menu_link:hover {
    color: #1b1d25;
    background-color: var(--yellow-orange);
}

#dark_mode_button {
    align-items: center;
    padding: 1rem 1.25rem;
    line-height: 1;
    background-color: #1d1d1d;
    border: none;
}

.intro_container {
    margin-top: 5rem;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    padding: 4.5rem 0;
    background-color: var(--light-background);
}

.intro_container p {
    max-width: 750px;
    font-size: 1.4rem;
    line-height: 1.6;
    margin: 0 0 1.5rem;
    font-family: var(--font-family);
    color: var(--font-color);
}

.intro_container h1 {
    line-height: 1.1;
    letter-spacing: -.1rem;
    font-weight: 700;
    font-size: 2.55rem;
    margin-bottom: 2rem;
    font-family: var(--heading-font-family);
    color: var(--heading-color);
}

a.github_button {
    font-family: var(--heading-font-family);
    border-radius: 0;
    color: #fff;
    background-color: var(--green);
    font-size: 1.2rem;
    padding: .75rem 1rem;
    border: 3px solid var(--border-color);
    box-shadow: 5px 5px var(--border-color);
    text-align: center;
    margin: 0 .25rem .5rem;
    line-height: 1.3;
}

a.github_button:hover {
    background-color: var(--light-green);
    border: 3px solid var(--border-color);
    transition: none;
}

.profile_img img {
    margin: 1.5rem auto 0;
    border: 3px solid var(--border-color);
    margin-top: 0;
    max-height: none;
    max-width: 350px;
    margin-left: 2rem;
}

.description_container {
    margin-top: 5rem;
    padding: 3.5rem 0;
    background-color: var(--light-background);
}

.description_container h1 {
    font-size: 2.5rem;
    display: inline-block;
    font-weight: 600;
    letter-spacing: -.1rem;
    scroll-margin-top: 80px;
    font-family: var(--heading-font-family);
    -webkit-font-smoothing: subpixel-antialiased;
    line-height: 1.1;
    margin: 0 0 1.5rem;
}

.description_container p {
    font-size: 1.6rem;
    font-family: var(--font-family);
    line-height: 1.5;
    font-weight: 300;
    margin-bottom: 0;
    margin: 0 0 1.5rem;
}

.smallinfo_container {
    background-color: var(--background);
    padding: 3.5rem 0;
}

.smallinfo_container h1 {
    font-size: 1.6rem;
    font-family: var(--heading-font-family);
    border-bottom: 1px solid var(--border-color);

}

.smallinfo_container li{
    font-size: 1.4rem;
}

.smallinfo_container li::marker{
    /* color: var(--marker); */
    font-size: 1.1rem;
    line-height: 1;
}

.smallinfo_container a{
    text-decoration-line: underline;
    text-decoration-thickness: 2px;
    color: var(--link-color);
}

.about_me_info {
    margin: 2rem 0;
}

.about_me_info p {
    font-size: 1.4rem;
    margin: 0 0 1.5rem;
}


@media screen and (max-width: 767px) {
    a.head_img_txt_link {
        padding-top: 0.5rem;
        padding-right: 0px;
        padding-bottom: 0;
        padding-left: 0px;
    }

    .menu-item {
        padding-top: 0.5rem;
        padding-right: 0px;
        padding-bottom: 0.8rem;
        padding-left: 0px;
    }

    .icon_img_text_container {
        font-size: 1.25rem;
    }

    .header_container {
        height: 7rem;
    }

    a.head_menu_link {
        font-size: 0.9rem;
        padding: 0.5rem 0.5rem;
        height: 2rem;
    }

    #dark_mode_button {
        font-size: 1.25rem;
        padding: 0.5rem 0.5rem;
        height: 2rem;
    }

    .intro_container p {
        font-size: 1.2rem;
    }

    .github_button {
        margin-top: 1rem;
        margin-right: 0.25rem;
        margin-bottom: 0.5rem;
        margin-left: 0.25rem;
    }

    .profile_img img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
        max-width: 280px;
        margin-top: 1.75rem;
    }

    .description_container {
        padding-top: 3.5rem;
        padding-right: 0px;
        padding-bottom: 2rem;
        padding-left: 0px;
    }

    .description_container p {
        font-size: 1.2rem;
    }

    .smallinfo_container h1{
        font-size: 1.4rem;
    }

    .smallinfo_container li{
        font-size: 1.05rem;
    }

    .about_me_info p {
        font-size: 1.05rem;
    }
}